import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <Slider {...settings} className="relative w-full h-screen">
      <div>
        <img
          src={"/images/general/top.png"}
          alt="hero_banner_1"
          width={1800}
          height={600}
          className="w-full h-screen object-cover"
        />
      </div>
      <div>
        <img
          src={"/images/general/side1.jpg"}
          alt="hero_banner_2"
          width={1800}
          height={600}
          className="w-full h-screen object-cover"
        />
      </div>
    </Slider>
  );
};

export default ImageCarousel;
