const condition = () => {
    return (
      <>
      <div className='bg-[#14274A] min-h-[100px] w-full'></div>
      <div className="max-w-7xl mx-auto my-16 shadow-lg px-4 md:px-0">
        <div className='px-8 lg:px-16 border-2 py-8'>
          <h1 className="font-bold text-2xl xl:text-3xl pb-8">Terms & Conditions</h1>
          <div className="text-md xl:text-lg space-y-4 pb-8">
            <p>- Quoted rates are all in Bhutanese Ngultrum.</p>
            <p>- The rate is exclusive of 10% Bhutan Sales Tax & 10 % Service Charge.</p>
          </div>

          <h1 className="font-bold text-2xl xl:text-3xl py-8">Payment Conditions</h1>
          <div className="text-md xl:text-lg space-y-4">
            <p>- 50% Payment required at the time of booking confirmation.</p>
            <p>- Balance payment shall be made during checkin.</p>
          </div>
        </div>
      </div>
      </>
    );
  };
  
  export default condition;
  
  