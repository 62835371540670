import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header";
import Form from "../../components/Book";

const Booking = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/images/general/booking.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Form />
    </div>
  );
};

export default Booking;
