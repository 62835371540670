import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../auth/authSlice";
import { apiSlice } from "../pages/booking/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { composeWithDevTools } from "@redux-devtools/extension";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false,
});

setupListeners(store.dispatch);
