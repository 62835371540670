import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'; // Import React Icons

function Checkout() {
  const [priceList, setPriceList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(''); // Start with an empty string
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [cardName, setCardName] = useState('');
  const [currency, setCurrency] = useState('USD'); // New state for currency
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    // Fetch data from the backend
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/checkout-details`) // Replace with your actual endpoint
      .then(response => {
        const { prices, total, checkInDate, checkOutDate } = response.data;
        setPriceList(prices);
        setTotalPrice(total);
        setCheckIn(checkInDate);
        setCheckOut(checkOutDate);
      })
      .catch(error => {
        console.error("There was an error fetching the checkout details!", error);
      });
  }, []);

  // Function to handle numeric-only input
  const handleNumericInput = (e, setter) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setter(value);
  };

  // Function to handle alphabetic-only input for card name
  const handleAlphabeticInput = (e, setter) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    setter(value);
  };

  // Function to perform local validation
  const localValidation = () => {
    const errors = {};
    if (!cardNumber.match(/^\d{16}$/)) {
      errors.cardNumber = "Card number must be 16 digits.";
    }
    if (!expiryDate.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)) {
      errors.expiryDate = "Expiry date must be in MM/YY format.";
    }
    if (!securityCode.match(/^\d{3}$/)) {
      errors.securityCode = "Security code must be 3 digits.";
    }
    if (cardName.trim() === '') {
      errors.cardName = "Cardholder name cannot be empty.";
    }
    return errors; // Return errors object
  };

  // Function to validate card data with API
  const handleCardValidation = () => {
    const validationData = {
      card_number: cardNumber,
      expiry_date: expiryDate,
      security_code: securityCode,
      card_name: cardName,
    };

    axios.post(
      process.env.REACT_APP_CARD_VALIDATION_API_URL,
      validationData,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(response => {
      if (response.data.valid) {
        alert("Card is valid! Proceeding with payment...");
        // Handle successful validation (e.g., submit the payment form)
      } else {
        setValidationErrors({
          ...validationErrors,
          apiError: "Invalid card details. Please check and try again."
        });
      }
    })
    .catch(error => {
      console.error("There was an error validating the card!", error);
      setValidationErrors({
        ...validationErrors,
        apiError: "An error occurred during card validation. Please try again."
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidationErrors({});

    // Perform local validation
    const errors = localValidation();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // If local validation passes, call API validation
    if (paymentMethod === 'card') {
      handleCardValidation();
    } else {
      alert("Payment method not implemented.");
    }
  };

  // Function to format price based on selected currency
  const formatPrice = (price) => {
    if (currency === 'INR') {
      return `₹ ${price * 80}`; // Assuming 1 USD = 80 INR
    }
    return `$ ${price}`;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-6 shadow-md rounded-md">
        <h2 className="text-2xl font-bold mb-4">Checkout</h2>
        {/* Horizontal line */}
        <hr className="my-4 border-gray-300" />

        {/* Display booking summary with total price */}
        <div className="flex items-center mb-4">
          <img 
            src="/images/logo/logo.png" 
            alt="Zebar Logo" 
            className="w-12 h-12 mr-4 object-contain"
          />
          <div>
            <h3 className="font-semibold">Room Booking</h3>
            <p className="text-sm text-gray-600">1 night stay</p>
          </div>
          <span className="ml-auto font-semibold">{formatPrice(totalPrice)}</span>
        </div>

        {/* Display check-in and check-out dates alongside the price list */}
        <div className="flex justify-between mb-4">
          {/* Booking Details */}
          <div className="w-1/2 pr-4">
            <h3 className="font-semibold">Booking Details</h3>
            <p className="text-sm text-gray-600">Check-In: {checkIn}</p>
            <p className="text-sm text-gray-600">Check-Out: {checkOut}</p>
          </div>

          {/* Price List */}
          <div className="w-1/2 pl-4">
            <h3 className="font-semibold">Price List</h3>
            <ul className="list-none">
              {priceList.map(({ item, price }, index) => (
                <li key={index} className="mb-2 flex justify-between items-center">
                  <span>{item}:</span>
                  <span>{formatPrice(price)}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
            
            {/* Currency selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Select Currency</label>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            <option value="USD">US Dollars</option>
            <option value="INR">Indian Rupees</option>
          </select>
        </div>
        {/* Horizontal line */}
        <hr className="my-4 border-gray-300" />

        {/* Payment method selection */}
        <h3 className="text-xl font-semibold mb-4 mt-4">Payment method</h3>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="flex items-center mb-2">
              <input
                type="radio"
                name="paymentMethod"
                value="card"
                checked={paymentMethod === 'card'}
                onChange={() => setPaymentMethod('card')}
                className="mr-2"
              />
              <span>Credit or debit card</span>
              <div className="ml-auto flex space-x-2">
                <FaCcVisa className="w-8 h-6 text-blue-600" /> {/* Visa icon */}
                <FaCcMastercard className="w-8 h-6 text-red-600" /> {/* Mastercard icon */}
              </div>
            </label>

            {paymentMethod === 'card' && (
              <div className="mt-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Card number</label>
                  <input
                    type="text"
                    placeholder="0000 0000 0000 0000"
                    value={cardNumber}
                    onChange={(e) => handleNumericInput(e, setCardNumber)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3"
                  />
                  {validationErrors.cardNumber && (
                    <p className="text-red-500 text-sm mt-2">{validationErrors.cardNumber}</p>
                  )}
                </div>
                <div className="flex space-x-4 mb-4">
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">Expiry date</label>
                    <input
                      type="text"
                      placeholder="MM/YY"
                      value={expiryDate}
                      onChange={(e) => handleNumericInput(e, setExpiryDate)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3"
                    />
                    {validationErrors.expiryDate && (
                      <p className="text-red-500 text-sm mt-2">{validationErrors.expiryDate}</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">Security code</label>
                    <input
                      type="text"
                      placeholder="000"
                      value={securityCode}
                      onChange={(e) => handleNumericInput(e, setSecurityCode)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3"
                    />
                    {validationErrors.securityCode && (
                      <p className="text-red-500 text-sm mt-2">{validationErrors.securityCode}</p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Name on card</label>
                  <input
                    type="text"
                    placeholder="Cardholder name"
                    value={cardName}
                    onChange={(e) => handleAlphabeticInput(e, setCardName)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3"
                  />
                  {validationErrors.cardName && (
                    <p className="text-red-500 text-sm mt-2">{validationErrors.cardName}</p>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="paymentMethod"
                value="rma"
                checked={paymentMethod === 'rma'}
                onChange={() => setPaymentMethod('rma')}
                className="mr-2"
              />
              <span>Royal Monetory Authority (RMA)</span>
              <div className="ml-auto flex space-x-2">
                <img 
                  src="/images/icons/rma-logo-white.png" 
                  alt="RMA Logo" 
                  className="w-8 h-6 object-contain" 
                /> {/* RMA logo */}
              </div>
            </label>
            {paymentMethod === 'rma' && (
              <div className="mt-4 text-sm text-gray-600">
                {/* Additional information or inputs for RMA can be added here */}
                <p>You have selected Reserve My Amount (RMA) as your payment method.</p>
              </div>

            )}
            
          </div>
            
          {/* Display general error message for API validation */}
          {validationErrors.apiError && (
            <p className="text-red-500 text-sm mb-4">{validationErrors.apiError}</p>
          )}

        <button type="submit" className="w-full bg-[#c19e68] text-white rounded-lg py-4 transition-transform duration-100 hover:scale-[1.03] text-xl">
            Complete purchase
        </button>

        </form>
      </div>
    </div>
  );
}

export default Checkout;
