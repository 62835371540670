import React from "react";
import FacilitiesLanding from "../../components/FacilitiesLanding";
import { motion } from "framer-motion";


const facilitiesData = [
  { src: "/images/general/swimmingPool.png", alt: "Swimming Pool" },
  { src: "/images/general/glassHouse.png", alt: "Glass House" },
  { src: "/images/general/conferance.png", alt: "Conference Room" },
  { src: "/images/general/Libary.png", alt: "Library" },
  { src: "/images/general/restaurant.png", alt: "Restaurant" },
  { src: "/images/general/lobby.png", alt: "Lobby" },
  { src: "/images/general/laundry.png", alt: "Laundry" },
];

const Facilities = () => {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };
  return (
    <motion.div>
      <FacilitiesLanding />

      <motion.div className="text-[#14274A] max-w-7xl mx-auto">
        <motion.div className="flex justify-center py-8">
          <motion.h1 
          className="font-garamond font-bold text-3xl xl:text-4xl"
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}>FACILITIES</motion.h1>
        </motion.div>
        <motion.p 
        className="font-montserrat text-lg xl:text-xl text-center"
        variants={textEffect}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: true }}>
          We want your stay at our lush hotel to be truly unforgettable. That is why we give special attention to all of your needs so that we can ensure an experience quite unique. Luxury hotels offer the perfect setting with stunning views for leisure, and our modern luxury resort facilities will help you enjoy the best of all.
        </motion.p>

        <motion.div className="py-16 space-y-16 flex flex-col items-center">
          {facilitiesData.map((facility, index) => (
            <motion.img
              key={index}
              src={facility.src}
              alt={facility.alt}
              className="w-full xl:w-2/3 px-4 xl:px-0"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            />
          ))}
        </motion.div>
        {/* <div className="flex flex-col justify-center items-center space-y-4 py-16">
            <h1 className="text-[#14274A] font-garamond text-4xl font-bold">Testimonials</h1>
            <p className="text-[#14274A] text-lg">This place knows how to create a peaceful, romantic retreat. We can't wait to return!</p>
            <p className="text-[#14274A] text-md font-light"> Mr. Pema and Mrs. Kezang</p>
        </div> */}
      </motion.div>
    </motion.div>
  );
};

export default Facilities;
