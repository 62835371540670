import React from "react";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const Details = ({ room, onBack, onPrevious, onNext, showPrevious, showNext }) => {
  const handleBackClick = () => {
    scrollToTop();
    onBack();
  };

  const handlePreviousClick = () => {
    scrollToTop();
    onPrevious();
  };

  const handleNextClick = () => {
    scrollToTop();
    onNext();
  };

  return (
    <>
      <div className='bg-[#14274A] min-h-[100px] w-full'></div>
      <div className='max-w-7xl mx-auto px-8 lg:px-16 py-8'>
        <h1 className="flex justify-center font-garamond text-4xl font-bold pt-8 pb-12">{room.name}</h1>
        <div className="flex flex-col lg:flex-row lg:space-x-16">
          <img
            src={room.imgSrc}
            className="w-full lg:w-1/2 mb-8 lg:mb-0"
            alt={room.name}
          />
          <div>
            <p className="text-xl font-bold pb-4 font-garamond">Room Amenities</p>
            <ul className="space-y-2 font-montserrat">
              {room.details.amenities.map((amenity, index) => (
                <li key={index}>{index + 1}. {amenity}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-16">
          <h1 className="text-xl font-bold pb-4 font-garamond">Local Rates</h1>
          <table className="table-auto w-full font-montserrat border border-collapse border-gray-200">
            <thead>
              <tr className="bg-gray-200">
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Plan</th>
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Facilities</th>
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Price</th>
              </tr>
            </thead>
            <tbody>
              {room.details.localRates.map((rate, index) => (
                <tr key={index} className="border border-gray-200">
                  <td className="p-2 border border-gray-200">{rate.plan}</td>
                  <td className="p-2 border border-gray-200">{rate.facilities}</td>
                  <td className="p-2 border border-gray-200">{rate.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-16">
          <h1 className="text-xl font-bold pb-4 font-garamond">International Rate</h1>
          <table className="table-auto w-full font-montserrat border border-collapse border-gray-200">
            <thead>
              <tr className="bg-gray-200">
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Plan</th>
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Facilities</th>
              <th className="border border-gray-400 px-4 py-2 text-left font-montserrat font-semibold text-sm xl:text-base">Price</th>
              </tr>
            </thead>
            <tbody>
              {room.details.internationalRates.map((rate, index) => (
                <tr key={index} className="border border-gray-200">
                  <td className="p-2 border border-gray-200">{rate.plan}</td>
                  <td className="p-2 border border-gray-200">{rate.facilities}</td>
                  <td className="p-2 border border-gray-200">{rate.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-8 flex justify-center space-x-8 py-16">
        {showPrevious && (
          <button onClick={handlePreviousClick} className="bg-[#E0B973] text-white font-garamond px-4 py-2 rounded-md">
               Previous
          </button>
        )}
        <button onClick={handleBackClick} className="bg-[#E0B973] text-white font-garamond px-4 py-2 rounded-md">
            Overview
        </button>
        {showNext && (
        <button onClick={handleNextClick} className="bg-[#E0B973] text-white font-garamond px-6 py-2 rounded-md">
          Next
        </button>
         )}
        </div>
      </div>
    </>
  );
};

export default Details;
