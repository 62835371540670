import React from "react";
import { useLocation } from "react-router-dom";

const ProceedToPayment = () => {
  const location = useLocation();
  const { requestData } = location.state || {};
  console.log(requestData);
  return (
    <>
      <div>
        <h1>Payment Response</h1>
        {/* <p>{requestData}</p> */}
      </div>
      <form method="post" action="">
        <input
          type="hidden"
          value={requestData.bfs_msgType}
          name="bfs_msgType"
        />
        <input type="hidden" value={requestData.bfs_benfId} name="bfs_benfId" />
        <input
          type="hidden"
          value={requestData.bfs_benfTxnTime}
          name="bfs_benfTxnTime"
        />
        <input
          type="hidden"
          value={requestData.bfs_orderNo}
          name="bfs_orderNo"
        />
        <input
          type="hidden"
          value={requestData.bfs_benfBankCode}
          name="bfs_benfBankCode"
        />
        <input
          type="hidden"
          value={requestData.bfs_txnCurrencyCode}
          name="bfs_txnCurrencyCode"
        />
        <input
          type="hidden"
          value={requestData.bfs_txnAmount}
          name="bfs_txnAmount"
        />
        <input
          type="hidden"
          value={requestData.bfs_remitterEmail}
          name="bfs_remitterEmail"
        />
        <input
          type="hidden"
          value={requestData.bfs_paymentDesc}
          name="bfs_paymentDesc"
        />
        <input
          type="hidden"
          value={requestData.bfs_version}
          name="bfs_version"
        />
        <input
          type="hidden"
          value={requestData.bfs_checkSum}
          name="bfs_checkSum"
        />
      </form>
    </>
  );
};

export default ProceedToPayment;
