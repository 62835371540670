import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { motion , AnimatePresence } from 'framer-motion';

const HeaderForm = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [isLogoSmall, setIsLogoSmall] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const halfwayPoint = window.innerHeight / 2;
    const newOpacity = scrollY >= halfwayPoint ? 1 : scrollY / halfwayPoint;
    setOpacity(newOpacity);

    setIsLogoSmall(scrollY > 0);
  };

  const fadeInDown = {
    initial: {
      y: -20,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.33, 1, 0.68, 1], delay: 0.5 },
    },
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const headerBackgroundColor = `rgba(137, 118, 75, ${opacity})`;
  const navbarStyle = {
    paddingTop: isLogoSmall ? "0px" : "8px", // Adjust the padding top
    paddingBottom: isLogoSmall ? "0px" : "8px", // Adjust the padding bottom
  };

  // Inline style for the logo transformation
  const logoStyle = {
    transition: "transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
    transform: isLogoSmall ? "scale-75" : "scale-100", // Tailwind CSS scale classes
  };

  return (
    <>
      <motion.header
        initial="initial"
        animate="enter" 
        className={`fixed flex z-[3] text-white top-0 px-2 md:px-16 justify-between w-full box-border items-center transition-all duration-300`}
        style={{ backgroundColor: headerBackgroundColor, ...navbarStyle }}
      >
        {/* Left side with logo */}
        <motion.div variants={fadeInDown} className="flex items-center">
          <a href="/">
            <motion.img
              src="/images/logo/logo.png"
              alt="Logo"
              className={`mr-4 ml-4 sm:ml-16 w-20 h-20 transition-transform duration-500 transform-gpu ${
                isLogoSmall ? "scale-75" : "scale-100"
              }`}
              style={logoStyle} // Apply the inline style
            />
          </a>
        </motion.div>
        {/* Centered navigation links */}
        <motion.nav variants={fadeInDown} className="hidden xl:flex justify-end flex-grow space-x-12 text-[16px] font-bold px-16">
          <a href="/" className="relative group">
            Home
            <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </a>
          <a href="/facilities" className="relative group">
              Facilities
            <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </a>
          <a href="/rooms" className="relative group">
            Rooms
            <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </a>
          <a href="/contact" className="relative group">
            Contact
            <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </a>
        </motion.nav>
        {/* Toggle button for mobile and tablet */}
        <motion.button
          className="xl:hidden focus:outline-none fixed top-7 right-2 mr-8 sm:mr-16 z-50"
          onClick={toggleMenu}
        >
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </motion.button>
        {/* Navigation links for mobile and tablet */}
        <AnimatePresence>
        {isMenuOpen && (
          <motion.div 
          exit={{ opacity: 0 }}
          transition={{ type: 'tween', duration: 0.3 }}
          className="xl:hidden fixed top-0 right-0 h-screen w-1/2 bg-[#89764B] z-50">
            <motion.div 
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.5 }}
            className="flex flex-col items-end mr-8 md:mr-16 py-8 space-y-8 md:text-[16px]">
              <motion.button
                className="text-white text-3xl top-0 right-4"
                onClick={toggleMenu}
              >
                &times;
              </motion.button>
              <a href="/" className="text-black">
                Home
              </a>
              <a href="/facilities" className="text-black">
                Facilities
              </a>
              <a href="/rooms" className="text-black">
                Rooms
              </a>
              <a href="/contact" className="text-black">
                Contact
              </a>
            </motion.div>
          </motion.div>
        )}
        </AnimatePresence>
      </motion.header>
    </>
  );
};

export default HeaderForm;
