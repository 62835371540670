import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import { motion } from "framer-motion";

const FacilitiesLanding = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Text animation for "WELCOME TO ZEEBAR RESORT" (left to right)
  const leftToRight = {
    offscreen: {
      x: -150,
    },
    onscreen: {
      x: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  // Text animation for "Book your stay and enjoy Luxury redefined at the most affordable rates." (right to left)
  const rightToLeft = {
    offscreen: {
      x: 150,
    },
    onscreen: {
      x: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  const handleBookNow = () => {
    navigate("/booking"); // Navigate to /booking route
  };

  return (
    <motion.main className="relative flex h-screen overflow-hidden w-full">
      <img
        src={"/images/general/landing2.png"}
        alt="hero_banner"
        width={1800}
        height={600}
        className="absolute w-full h-full object-cover"
        style={{
          left: "0",
          right: "0",
          margin: "auto",
        }}
      />

      <motion.div
        className="absolute w-full h-full bg-[#14274A] opacity-50"
        style={{
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
        }}
      />

<motion.div className="w-full h-full absolute z-0" />
      <motion.div className="flex flex-col items-center justify-center gap-1 xl:gap-1 absolute bottom-[5%] transform -translate-x-1/2 left-1/2">
        <motion.div
          className="text-center flex items-center justify-center"
          variants={leftToRight}
          initial="offscreen"
          animate="onscreen"
        >
          <motion.p
            className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl text-white whitespace-nowrap"
            style={{ fontFamily: "Cinzel, serif" }}
          >
            ZEEBAR RESORT
          </motion.p>
        </motion.div>
        <motion.div
          className="text-white tracking-medium lg:w-[60%] xl:px-0 text-center uppercase"
          variants={rightToLeft}
          initial="offscreen"
          animate="onscreen"
          style={{ fontFamily: "Montserrat, sans-serif" }}
        >
          Luxury redefined at the most affordable
        </motion.div>
        <div className="py-10">
          <motion.button
            initial={{ opacity: 0, y: 150 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 }}
            className="bg-[#E0B973] px-2 py-2 text-base font-montserrat rounded-lg flex items-center justify-center w-[160px] h-[50px]"
            onClick={handleBookNow}
          >
            <img src="/images/icons/booking.svg" className="pr-2 w-[20px]" />
            BOOK NOW
          </motion.button>
        </div>
      </motion.div>
    </motion.main>
  );
};

export default FacilitiesLanding;
