import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative">
        <img src='/images/logo/logo.png' alt="Zeebar Resort Logo" className="w-32 h-32" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-40 w-40 border-t-4 border-b-4 border-orange-500"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
