import React, { useState } from "react";
import RoomsLanding from "../../components/RoomsLanding";
import { motion } from "framer-motion";
import Details from "./RoomDetails/details";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const rooms = [
  {
    id: 1,
    name: "Single Room",
    imgSrc: "/images/general/room1.png",
    details: {
      amenities: [
        "Free Wi-Fi.",
        "Cable TV.",
        "Complementary Tea & Coffee.",
        "Water Kettle.",
        "Complementary 2 Bottles of water per day.",
        "Essentials (Towels, Soap, Shampoo & Moisturizer)",
        "Balcony",
        "Laundry Services.",
        "Air Condition in all rooms.",
        "Mini Freezer",
        "Hair Dryer.",
      ],
      localRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.1800" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.2100",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.2700",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.3500",
        },
      ],
      internationalRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.2100" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.2450",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.3100",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.4000",
        },
      ],
    },
  },
  {
    id: 2,
    name: "Twin Room",
    imgSrc: "/images/general/room2.png",
    details: {
      amenities: [
        "Free Wi-Fi.",
        "Cable TV.",
        "Complementary Tea & Coffee.",
        "Water Kettle.",
        "Complementary 2 Bottles of water per day.",
        "Essentials (Towels, Soap, Shampoo & Moisturizer)",
        "Balcony",
        "Laundry Services.",
        "Air Condition in all rooms.",
        "Mini Freezer",
        "Hair Dryer.",
      ],
      localRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.2400" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3000",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.4200",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.5800",
        },
      ],
      internationalRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.3400" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.4100",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.5400",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.6850",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Deluxe Room",
    imgSrc: "/images/general/room3.png",
    details: {
      amenities: [
        "Free Wi-Fi.",
        "Cable TV.",
        "Complementary Tea & Coffee.",
        "Water Kettle.",
        "Complementary 2 Bottles of water per day.",
        "Essentials (Towels, Soap, Shampoo & Moisturizer)",
        "Balcony",
        "Laundry Services.",
        "Air Condition in all rooms.",
        "Mini Freezer",
        "Hair Dryer.",
      ],
      localRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.2700" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3000",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.3600",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.4400",
        },
      ],
      internationalRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.2700" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3050",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.3700",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.4600",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Suite 1 Room",
    imgSrc: "/images/general/room4.png",
    details: {
      amenities: [
        "Free Wi-Fi.",
        "Cable TV.",
        "Complementary Tea & Coffee.",
        "Water Kettle.",
        "Complementary 2 Bottles of water per day.",
        "Essentials (Towels, Soap, Shampoo & Moisturizer)",
        "Balcony",
        "Laundry Services.",
        "Air Condition in all rooms.",
        "Mini Freezer",
        "Hair Dryer.",
      ],
      localRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.3200" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3500",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.4100",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.4900",
        },
      ],
      internationalRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.3200" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3550",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.4200",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.5100",
        },
      ],
    },
  },
  {
    id: 5,
    name: "Suite 2 Room",
    imgSrc: "/images/general/room5.png",
    details: {
      amenities: [
        "Free Wi-Fi.",
        "Cable TV.",
        "Complementary Tea & Coffee.",
        "Water Kettle.",
        "Complementary 2 Bottles of water per day.",
        "Essentials (Towels, Soap, Shampoo & Moisturizer)",
        "Balcony",
        "Laundry Services.",
        "Air Condition in all rooms.",
        "Mini Freezer",
        "Hair Dryer.",
      ],
      localRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.3600" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.3900",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.4500",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.5300",
        },
      ],
      internationalRates: [
        { plan: "European Plan", facilities: "Only Room", price: "Nu.3700" },
        {
          plan: "Continental Plan",
          facilities: "Room with Breakfast",
          price: "Nu.4050",
        },
        {
          plan: "Modified American Plan",
          facilities: "Room with Breakfast & any one meal",
          price: "Nu.4700",
        },
        {
          plan: "American Plan",
          facilities: "Room with all Three meals",
          price: "Nu.5600",
        },
      ],
    },
  },
];

const Rooms = () => {
  const [selectedRoom, setSelectedRoom] = useState(null);

  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  const handlePreviousRoom = () => {
    const currentIndex = rooms.findIndex((room) => room.id === selectedRoom.id);
    const previousIndex = (currentIndex - 1 + rooms.length) % rooms.length;
    setSelectedRoom(rooms[previousIndex]);
  };

  const handleNextRoom = () => {
    const currentIndex = rooms.findIndex((room) => room.id === selectedRoom.id);
    const nextIndex = (currentIndex + 1) % rooms.length;
    setSelectedRoom(rooms[nextIndex]);
  };
  const handleViewRoomDetails = (room) => {
    scrollToTop(); // Scroll to top when viewing room details
    setSelectedRoom(room);
  };

  return (
    <motion.div>
      {selectedRoom ? (
        <Details
          room={selectedRoom}
          onBack={() => setSelectedRoom(null)}
          onPrevious={handlePreviousRoom}
          onNext={handleNextRoom}
          showPrevious={selectedRoom.id !== rooms[0].id}
          showNext={selectedRoom.id !== rooms[rooms.length - 1].id}
        />
      ) : (
        <>
          <RoomsLanding />
          <motion.div className="text-black max-w-7xl mx-auto">
            <motion.div className="flex justify-center py-8">
              <motion.h1
                className="font-garamond font-bold text-3xl xl:text-4xl"
                variants={textEffect}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true }}
              >
                ROOMS AND RATES
              </motion.h1>
            </motion.div>

            <motion.p
              className="font-montserrat text-lg xl:text-xl px-4 xl:px-0 text-center"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            >
              Each of our bright, light-flooded rooms come with everything you
              could possibly need for a comfortable stay. And yes, comfort isn’t
              our only objective, we also value good design, sleek contemporary
              furnishing complemented by the rich tones of nature’s palette as
              visible from our rooms’ sea-view windows and terraces.
            </motion.p>

            <motion.div className="grid grid-cols-1 md:grid-cols-2 gap-12 py-16 mx-4 xl:mx-0">
              {rooms.map((room) => (
                <motion.div
                  key={room.id}
                  className="flex flex-col items-center border border-black rounded-md w-full"
                  variants={textEffect}
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true }}
                >
                  <motion.img
                    src={room.imgSrc}
                    className="w-full"
                    alt={`Room ${room.id}`}
                  />
                  <motion.div className="flex flex-row justify-between w-full px-4 py-8">
                    <motion.div className="flex flex-row space-x-2 items-center">
                      <motion.img
                        src="/images/icons/plus.svg"
                        className="w-4 xl:w-6"
                        alt="View Room Details"
                      />
                      <button onClick={() => handleViewRoomDetails(room)}>
                        <motion.p className="font-garamond text-sm xl:text-lg">
                          VIEW ROOM DETAILS
                        </motion.p>
                      </button>
                    </motion.div>
                    <a href="/booking">
                      <motion.button className="text-white text-sm xl:text-lg font-garamond bg-[#E0B973] px-2 xl:px-4 py-2 rounded-md">
                        BOOK NOW
                      </motion.button>
                    </a>
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </>
      )}
    </motion.div>
  );
};

export default Rooms;
