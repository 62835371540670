import React from 'react';
import HeaderForm from '../../components/BookHeader';
import RoomType from '../../components/RoomType';

const Roomtype = () => {
  return (
    <div>
      <HeaderForm/>
      <RoomType />
    </div>
  );
};

export default Roomtype;
