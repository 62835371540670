import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Booking from "./pages/booking/booking";
import Layout from "./components/Layout";
import Facilities from "./pages/facilities/facilities";
import Rooms from "./pages/rooms/rooms";
import Contact from "./pages/contact/contact";
import Roomtype from "./pages/booking/roomtype";
import RoomDetails from "./components/RoomDetails";
import Details from "./pages/rooms/RoomDetails/details";
import Condition from "./components/condition";
import ErrorPage from "./components/ErrorPage"; // Import the ErrorPage component
import Checkout from "./components/Checkout";
import NoHeaderFooterLayout from "./components/NoHeaderFooterLayout"; // Import the new layout
import SuccessPage from "./pages/success/SuccessPage";
import FailurePage from "./pages/failure/FailurePage";
import CancelPage from "./pages/cancel/CancelPage";
import ProceedToPayment from "./components/ProceedToPayment";
export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="facilities" element={<Facilities />} />
        <Route path="rooms" element={<Rooms />} />
        <Route path="contact" element={<Contact />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/roomtype" element={<Roomtype />} />
        <Route path="/roomdetails/:roomId" element={<RoomDetails />} />
        <Route path="/rooms/detail" element={<Details />} />
        <Route path="/condition" element={<Condition />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/failure" element={<FailurePage />} />
        <Route path="/cancel" element={<CancelPage />} />
      </Route>
      <Route path="/checkout" element={<NoHeaderFooterLayout />}>
        <Route index element={<Checkout />} />
      </Route>
      <Route path="/proceedToPayment" element={<NoHeaderFooterLayout />}>
        <Route index element={<ProceedToPayment />} />
      </Route>
      {/* Add the catch-all route for 404 errors */}
      {/* <Route path="*" element={<ErrorPage />} /> */}
    </Routes>
  );
}
