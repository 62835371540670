const Footer = () => {
  return (
    <>
      <div className="bg-[#14274A] xl:py-16 py-8 px-16 xl:px-24">
        <div className="flex flex-col xl:flex-row justify-between px-0 xl:px-12 max-w-7xl mx-auto text-center xl:text-start">
          <div className="text-white pb-8 xl:pb-0">
            <p className="text-3xl font-bold font-garamond tracking-medium">
              ZEEBAR
            </p>
            <p className="font-garamond text-xl tracking-wide">Resort</p>
            <p className="text-md font-montserrat">
              Gawadrong (Allay Pakha), Samtse, Bhutan
            </p>
            <p className="text-md font-montserrat">+975 77247777</p>
            <p className="text-md font-montserrat">zeebarresort@gmail.com</p>
          </div>

          <div className="text-white font-semibold text-md font-montserrat space-y-4 xl:space-y-2 xl:pb-0 pb-8 xl:mr-16">
            <p>
              <a href="/facilities" className="relative group">
                Facilities
                <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </p>
            <p>
              <a href="/rooms" className="relative group">
                Rooms
                <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </p>
            <p>
              <a href="/contact" className="relative group">
                Contact
                <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </p>
            <p>
              <a href="/condition" className="relative group">
                Terms & Condition
                <span class="absolute left-0 right-0 bg-white h-0.5 bottom-0 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </a>
            </p>
          </div>

          <div className="pb-8 xl:pb-0 flex flex-row xl:flex-col justify-center xl:justify-start space-x-4 xl:space-x-0 xl:mr-32">
            <a
              href="https://www.instagram.com/zeebarresort?igsh=OHh1bXIwbDVuenpq"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row text-white font-semibold text-md font-montserrat"
            >
              <img src="/images/icons/IG.svg" alt="Instagram Icon" />
              <p className="mt-1 pl-2 hidden xl:block">Instagram</p>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100091537659714&mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row text-white font-semibold text-md font-montserrat xl:mt-4"
            >
              <img src="/images/icons/FB.svg" alt="Facebook Icon" />
              <p className="mt-1 pl-4 hidden xl:block">Facebook</p>
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-4 mt-6">
          {" "}
          {/* Adjusted margin here */}
          <a
            href="https://www.nomindbhutan.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-4"
          >
            <p className="font-bold text-[12px] text-white">Powered By</p>
            <img src="/Powered.svg" alt="icon" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
