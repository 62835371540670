import React from "react";
import Landing from "../../components/Landing";
import { motion } from "framer-motion";

export default function Home() {
  const textEffect = {
    offscreen: {
      y: 150,
    },
    onscreen: {
      y: 0,
      transition: { duration: 1.0, ease: [0.33, 1, 0.68, 1], delay: 0 },
    },
  };

  return (
    <motion.div>
      <Landing />
      <motion.div className="max-w-7xl mx-auto">
        <motion.div
          variants={textEffect}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true }}
          className="pb-24 w-full max-w-7xl mx-auto space-y-16 xl:space-y-24 pt-8 xl:pt-24"
        >
          <motion.div
            variants={textEffect}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true }}
            className="p-8 flex flex-col lg:flex-row items-center lg:space-x-8 xl:space-x-24 space-y-8 xl:space-y-0"
          >
            <motion.div className="relative w-full xl:w-1/2 mt-8 lg:mt-0 order-last lg:order-first">
              <img
                src="/images/general/Suite_1.jpeg"
                width={150}
                height={150}
                alt="work"
                className="w-full h-auto"
              />
            </motion.div>
            <motion.div className="text-black w-full xl:w-1/2 order-first lg:order-last">
              <p className="font-bold font-garamond text-[24px] lg:text-[30px] pb-4">
                Our Vision
              </p>
              <p className="border-b-2 border-[#E0B973] w-1/6 items-start mb-4"></p>
              <p className="text-lg mb-8 font-montserrat font-light  text-[#14274A]">
                We aspire to become the most preferred 3-star premier resort in
                Samtse delivering an exceptional hospitality experience that
                reflects and blends the unique artistry, culture and the
                heritage of traditional Bhutanese architecture with the comfort
                of modern amenities.
              </p>
            </motion.div>
          </motion.div>

          <motion.div
            variants={textEffect}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true }}
            className="p-8 flex flex-col lg:flex-row items-center lg:space-x-8 xl:space-x-24 space-y-8 xl:space-y-0 "
          >
            <motion.div className="text-black text-right w-full xl:w-1/2">
              <p className="font-bold font-garamond text-[24px] lg:text-[30px] pb-4">
                Our Mission
              </p>
              <p className="border-b-2 border-[#E0B973] w-1/6 mb-4 ml-auto"></p>
              <p className="text-lg mb-8 font-montserrat font-light  text-[#14274A]">
                We commit to offer our travelers, guests, colleagues, and
                partners a genuine hospitality, care, comfort and a valued
                experience by providing the highest quality of customer service
                and facilities through our warm, relaxed, yet refined ambiance
                exceeding their expectations. Our goal is to make Zeebar Resort
                the ultimate place for encounters, celebrations, pleasant
                meetings and gala ceremonies.
                <br />
                <br />
                Zeebar resort is in constant pursuit of excellence through our
                dedicated team of nurtured staff who takes pride at creating an
                engaging and lifelong memories so that every guest returns for
                year round adventure in Samtse!
              </p>
            </motion.div>

            <motion.div className="relative w-full xl:w-1/2">
              <img
                src="/images/general/pool3.jpg"
                alt="work"
                className="w-full h-auto"
              />
            </motion.div>
          </motion.div>
        </motion.div>

        <motion.div className="flex justify-center mt-8 xl:my-12">
          <motion.h1
            className="font-montserrat text-[#14274A] font-light text-2xl xl:text-3xl text-center"
            variants={textEffect}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true }}
          >
            All our room types are including complementary breakfast
          </motion.h1>
        </motion.div>

        <motion.div className="flex flex-col xl:flex-row xl:space-x-4 py-12 text-[#14274A]">
          <motion.div className="hidden xl:block border-l border-1 border-[#14274A]"></motion.div>
          <motion.div className="flex flex-col space-y-8 px-6 xl:px-0 py-4 xl:py-0">
            <motion.h1
              className="text-4xl font-garamond font-bold"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            >
              Luxury redefined
            </motion.h1>
            <motion.p
              className="font-montserrat w-full xl:w-[90%] text-lg font-light"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            >
              Our rooms are designed to transport you into an environment made
              for leisure. Take your mind off the day-to-day of home life and
              find a private paradise for yourself.
            </motion.p>
            <a href="/rooms">
              <motion.button
                className="font-montserrat text-white px-6 py-2 rounded-md bg-[#E0B973] self-start mt-auto"
                variants={textEffect}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true }}
              >
                EXPLORE
              </motion.button>
            </a>
          </motion.div>
          <motion.img
            src="/images/general/Suite_2.png"
            className="w-full xl:w-1/2 px-4"
            variants={textEffect}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true }}
          />
        </motion.div>

        <motion.div className="flex flex-col xl:flex-row xl:space-x-4 py-12 text-[#14274A]">
          <motion.div className="hidden xl:block border-l border-1 border-[#14274A]"></motion.div>
          <motion.div className="flex flex-col space-y-8 px-6 xl:px-0 py-4 xl:py-0">
            <motion.h1
              className="text-4xl font-garamond font-bold"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            >
              Dive into Serenity
            </motion.h1>
            <motion.p
              className="font-montserrat w-full xl:w-[90%] text-lg font-light"
              variants={textEffect}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: true }}
            >
              Leave your worries at the poolside. We love life by the pool.
              Being close to the water with access to a pristine swimming pool
              ensures a relaxed state of mind. It feels like time stands still
              as you float effortlessly.
            </motion.p>
            <a href="/facilities">
              <motion.button
                className="font-montserrat text-white px-6 py-2 rounded-md bg-[#E0B973] self-start mt-auto"
                variants={textEffect}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true }}
              >
                EXPLORE
              </motion.button>
            </a>
          </motion.div>
          <motion.img
            src="/images/general/landing2.png"
            className="w-full xl:w-1/2 px-4"
            variants={textEffect}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true }}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
